.fileInput {
  .label {
    margin-bottom: 0.2rem;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 600;
    span {
      cursor: pointer;
      text-decoration: underline;
      color: rgb(0, 178, 255);
    }
  }
  .inputField {
    border-radius: 4px;
    border: 1px solid #b7b7b7;
    label {
      cursor: pointer;
      display: grid;
      grid-template-columns: 1fr max-content;
      align-items: center;
      font-size: 13.5px;
      .fileNames {
        padding: 0 6px;
        color: #747474;
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .btn {
        border-radius: 0 3px 3px 0;
        padding: 5px 8px;
        background: rgb(0, 178, 255);
        color: white;
      }
    }
  }
  .files {
    border: 1px solid rgb(108, 108, 108);
    border-radius: 0.5rem;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 1rem;
    li {
      border: 1px solid rgb(108, 108, 108);
      border-radius: 0.5rem;
      background: white;
      height: 120px;
      width: 120px;
      overflow: hidden;
      display: grid;
      place-items: center;
      position: relative;
      .clear {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 0.5rem;
        color: rgb(194, 0, 64);
        font-size: 2rem;
        top: 0rem;
        right: 0rem;
        position: absolute;
      }
      &.file {
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      &.fileInput {
        label {
          display: grid;
          color: rgb(68, 68, 68);
          font-size: 1.6rem;
          width: 100%;
          height: 100%;
          cursor: pointer;
          &:hover {
            background: rgb(180, 180, 180);
          }
          svg {
            display: block;
            margin: auto;
          }
        }
      }
    }
  }
  &.error {
    .inputField {
      border-color: red;
    }
    .files {
      border-color: red;
    }
    .errMsg {
      font-size: 10pt;
      color: red;
      text-align: left;
      display: block;
    }
  }
}
